* {
    margin: 0px;
    padding: 0px;
}

.boby {
    background-color: white;
}

/* General Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    color: #333;
    padding: 0;
    margin: 0;
}

/* Navbar Styles */
.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    z-index: 1000;
}

.logo {
    font-size: 24px;
    font-weight: bold;
}

.navbar-links {
    list-style: none;
    display: flex;
    gap: 20px;
}

.navbar-links a {
    text-decoration: none;
    color: white;
    font-size: 16px;
    transition: color 0.3s ease;
}

.navbar-links a:hover {
    color: #00bcd4;
}

/* Main Landing Page Styles */
.landing-page {
    padding-top: 70px; /* To prevent content overlap with fixed navbar */
    background-color: #f4f4f4;
    min-height: 100vh;
}

/* Responsive Adjustments */

/* Mobile (Portrait) */
@media (max-width: 600px) {
    .navbar {
        padding: 10px 15px;
    }

    .logo {
        font-size: 20px;
    }

    .navbar-links {
        display: none; /* Hide links on small screens */
    }

    .navbar-links a {
        font-size: 14px;
    }
}

/* Tablet */
@media (max-width: 768px) {
    .navbar {
        padding: 12px 18px;
    }

    .logo {
        font-size: 22px;
    }

    .navbar-links {
        display: flex;
        gap: 15px;
    }

    .navbar-links a {
        font-size: 15px;
    }
}

/* Larger Screens (Desktop) */
@media (min-width: 769px) {
    .navbar {
        padding: 15px 25px;
    }

    .logo {
        font-size: 24px;
    }

    .navbar-links {
        display: flex;
        gap: 20px;
    }

    .navbar-links a {
        font-size: 16px;
    }
}
.responsive-image {
    width: 100%; /* Make the image fill the container's width */
    max-width: 50vw; /* Limit the max width to 50% of the viewport width (for larger screens) */
    height: auto; /* Maintain the aspect ratio */
    display: block; /* Removes any extra space below the image */
    background: transparent; /* Remove any background */
    object-fit: contain; /* Ensure the image scales without distortion */
    margin-left: 0; /* Align to the left */
    margin-right: 0; /* Remove right margin */
    margin-top: 0; /* Remove top margin */
    padding-top: 0; /* Remove padding at the top */
}

@media (max-width: 768px) {
    .responsive-image {
        max-width: 100%; /* Ensure the image takes up full width on smaller screens */
        margin-left: auto; /* Center the image horizontally */
        margin-right: auto; /* Center the image horizontally */
        top: 0; /* Remove the offset on mobile */
    }
}

.text-container {
    position: absolute;
    right: 20px; /* Position text 20px from the right */
    top: 40%; /* Position the text vertically at 40% of the screen */
    transform: translateY(-50%); /* Center the text vertically */
    z-index: 5; /* Keep the text above other content */
    width: 30%; /* Set a width for the text container */
    padding: 10px; /* Add some padding around the text */
}

.about-text {
    font-size: 32px; /* Set a larger font size for PC view */
    color: #333; /* Dark gray color for the text */
    line-height: 1.6; /* Add some line height for better readability */
    text-align: left; /* Align text to the left */
    font-weight: normal; /* Use normal font weight */
    margin: 0; /* Remove default margin */
}

@media (max-width: 1024px) {
    .text-container {
        position: absolute; /* Keep text absolute on tablets */
        right: 10px; /* Slightly reduce the space from the right */
        left: auto; /* Ensure no left positioning on smaller devices */
        width: 40%; /* Increase width on medium-sized screens */
    }

    .about-text {
        font-size: 18px; /* Adjust font size slightly for tablets */
    }
}

@media (max-width: 768px) {
    .text-container {
        position: relative; /* Adjust positioning for smaller screens */
        right: 0; /* Align the text with no right margin */
        top: auto; /* Remove vertical centering for mobile */
        transform: none; /* Remove transform for mobile */
        width: 90%; /* Set the text container to take up 90% of the screen width on mobile */
    }

    .about-text {
        font-size: 17px; /* Keep the same font size on smaller screens */
        line-height: 1.5; /* Adjust line height for mobile */
    }
}

@media (min-width: 1025px) {
    .text-container {
        left: 60%; /* Move the text further to the right on large screens */
        right: auto; /* Remove the right positioning */
        width: 35%; /* Reduce width for larger screens */
    }
}
/* Basic transition for smooth fade-in effect */
.landing-page {
    opacity: 0;
    animation: fadeIn 2s ease-in-out forwards;
}

/* Animation for fading in */
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* Navbar links slide-in from the right */
.navbar-links a {
    opacity: 0;
    transform: translateX(30px);
    animation: slideIn 1s forwards ease-out;
    animation-delay: 0.5s; /* Delay to make it look staggered */
}

.navbar-links a:nth-child(2) {
    animation-delay: 0.7s; /* Stagger the second link */
}

/* Slide-in animation */
@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateX(30px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

/* Image hover scale effect */
.responsive-image {
    transition: transform 0.3s ease-in-out;
}

.responsive-image:hover {
    transform: scale(1.1); /* Scale up the image when hovered */
}

/* Text container fade-in from the bottom */
.text-container {
    opacity: 0;
    transform: translateY(30px);
    animation: fadeInUp 1.5s forwards ease-out;
    animation-delay: 1s;
}

/* Fade-in-up animation for the text */
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Mobile view adjustments */
@media (max-width: 768px) {
    .landing-page {
        padding-top: 50px; /* Add padding to avoid text being too close to the top */
    }

    .image-container {
        text-align: center;
    }

    .responsive-image {
        width: 100%; /* Ensure image takes full width */
        height: auto;
        max-width: 100%;
    }

    .text-container {
        margin-top: 20px; /* Add some space between the image and text */
        padding: 0 15px; /* Add some padding to prevent text from touching the sides */
    }

    .about-text {
        font-size: 16px; /* Make the text a bit smaller on mobile */
    }
}

/* Tablet view adjustments */
@media (min-width: 769px) and (max-width: 1024px) {
    .responsive-image {
        width: 80%; /* Image takes 80% width on tablets */
        margin: 0 auto; /* Center the image */
    }

    .text-container {
        margin-top: 30px;
    }

    .about-text {
        font-size: 20px;
    }
}

/* PC view adjustments */
@media (min-width: 1025px) {
    .text-container {
        position: absolute;
        top: 25%; /* Moved text closer to the top of the page */
        left: 55%; /* Slightly move the text to the right */
        transform: translate(-50%, -50%);
        text-align: left;
    }

    .about-text {
        font-size: 26px;
    }
}

/* Basic styling */
.skills-section {
    padding: 50px 20px;
    text-align: center;
    background-color: #f4f4f4;
    border-radius: 10px;
    margin-top: 50px;
    opacity: 0;
    transform: translateY(50px);
    animation: fadeInUp 1.5s ease-out forwards;
}

.skills-title {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 30px;
}

.skills-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    justify-items: center;
    opacity: 0;
    transform: translateY(50px);
    animation: fadeInUp 1.5s ease-out forwards;
    animation-delay: 0.5s;
}

.skill {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.skill-name {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #333;
}

.progress-bar {
    width: 100%;
    background-color: #ddd;
    border-radius: 5px;
    height: 10px;
    margin-bottom: 10px;
}

.progress-bar-fill {
    background-color: #4caf50;
    height: 100%;
    border-radius: 5px;
}

.percentage {
    font-size: 1rem;
    color: #333;
}

/* Scroll-triggered animation */
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .skills-title {
        font-size: 2rem;
    }

    .skill {
        padding: 15px;
    }

    .progress-bar {
        height: 8px;
    }

    .percentage {
        font-size: 0.9rem;
    }
}

/* Tablet view responsiveness */
@media (min-width: 769px) and (max-width: 1024px) {
    .skills-title {
        font-size: 2.2rem;
    }

    .skill {
        padding: 18px;
    }

    .progress-bar {
        height: 9px;
    }

    .percentage {
        font-size: 1rem;
    }
}

/* PC view responsiveness */
@media (min-width: 1025px) {
    .skills-title {
        font-size: 2.5rem;
    }

    .skill {
        padding: 20px;
    }

    .progress-bar {
        height: 10px;
    }

    .percentage {
        font-size: 1.2rem;
    }
}
